<template>
	<!-- 抽奖设置 -->
	<div class="el-content">
		<a-form :label-col="{ span: 3}" :wrapper-col="{ span: 12 }">
			<a-form-item>
				<template #label>
					<span>抽奖页面背景</span>
					<a-tooltip title="抽奖中心生效">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<kd-img-select :src="form.lottery_bg" @change="(url)=>{ form.lottery_bg = url }"></kd-img-select>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-button type="primary" @click="submitSave">保存</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import setModel from '@/api/set';
export default{
	setup(){
		const state = reactive({
			form:{
				lottery_bg:'',
			}
		})
		setModel.getFarmSet(['lottery_bg']).then(res=>{
			state.form.lottery_bg = res.lottery_bg || ''
		})

		const submitSave = ()=>setModel.setFarmSet(state.form)

		return{
			...toRefs(state),
			submitSave
		}
	}
}
</script>

<style>
</style>
